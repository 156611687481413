/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { routerShape } from 'react-router';
import { JsonLd } from 'react-schemaorg';
import map from 'lodash/map';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import urlShortener from 'helpers/urlShortener';
import beautifyNumber from 'helpers/beautifyNumber';
import { FavoritesButton } from 'subsys/favorites';
import Link from 'components/link';
import sensor from 'components/sensor';
import Row, { Col } from 'components/row';
import Button from 'components/button';
import { Thumb } from 'components/image';
import { AccordionBlock } from 'components/accordion';

import AsideBlockList from './aside-block-list';
import BannerForumsList from '../common/banner-forums-list';

import linksForum from 'src-sites/forum/links';
import linksDomik from 'src-sites/domik/links';

import translates from '../forum-i18n';

import 'styles/forum/page-home/_page-home.scss';
import 'styles/forum/section-forum/_item-developer.scss';

const bemClassesAccordion = new Bem('accordion');
const bemClassesHome = new Bem('pageMain');
const bemClassesBlockForumList = new Bem('forumList');

@I18nHoc(translates())
export default class ForumListMain extends Component {
  static propTypes = {
    router: routerShape,
    className: PropTypes.string,
    entity: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({}))
    ]),
    renderContentBlock: PropTypes.func,
    showTitle: PropTypes.bool
  };

  // Show block with forums for category.
  getRootForBlock =(data = {})=> {
    const { renderContentBlock } = this.props;
    // const parent = get(childrenData, '[0].parent');
    const parent = get(data, '[0].parent');

    return (
      <div>
        <Row mtColumn mpColumn>
          <Col mdAdjust={renderContentBlock && 300} allPr={0}>
            {map(data, (item, index) => (
              <Fragment key={`forum-${item.id}-${index}`}>
                <ForumListMainItem
                  data={item}
                  preOpened={item.settings && item.settings.collapsed_subforums}
                />
              </Fragment>
            ))}
          </Col>
          {renderContentBlock && parent &&
            <Col mdCw={300} allPr={0}>
              {renderContentBlock(`rightColumn${parent}`)}
            </Col>
          }
        </Row>
      </div>
    );
  };

  render() {
    const { i18n } = this;
    const { className, data, showTitle = true, entity, renderContentBlock } = this.props;

    let dataObj = get(data, 'children') || data;
    if (!dataObj || !dataObj.length) dataObj = data;
    if (!dataObj || !dataObj.length) return null;
    let trackAs = get(dataObj, '[0].parent');
    if (entity) trackAs = `${trackAs} ${entity}`;

    if (!showTitle) {
      return (
        <div {...bemClassesBlockForumList('container', null, className)}>
          {this.getRootForBlock(dataObj)}
        </div>
      );
    }

    return (
      <>
        <AccordionBlock
          {...bemClassesHome('accordion')}
          panelBefore={entity !== 'page-forum' ? checkParentTitle(dataObj[0], bemClassesAccordion, 'accordion') : null}
          panelTopic={entity !== 'page-forum' ? '' : i18n('forums')}
          dataView={entity !== 'page-forum' ? 'viewBlockForumLink' : 'viewBlockForum'}
          trackAs={trackAs}
        >
          {this.getRootForBlock(dataObj)}
        </AccordionBlock>
        {entity === 'page-home' && renderContentBlock(`under${trackAs}`)}
      </>
    );
  }
}

@sensor
@I18nHoc(translates())
export class ForumListMainItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({}),
    object: PropTypes.shape({}),
    preOpened: PropTypes.bool,
    inFavorites: PropTypes.bool
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {};
  }

  render() {
    const { i18n } = this;
    const { object, inFavorites } = this.props;
    const data = object || { ...this.props.data };
    const children = get(data, 'children');
    const isDeveloper = get(data, 'developer_id');
    const imgId = isDeveloper ? get(data, 'dev_avatar') : get(data, 'lc_images.bild[0]');
    const websiteDeveloper = get(data, 'dev_website');
    const forum_title = get(data, 'forum_title');
    const imgDeveloper = { id: imgId, alt: forum_title, title: forum_title };
    let childrenContent = map(children, (item, key) => {
      const show_subforums_list = get(item, 'settings.show_subforums_list');

      if (!show_subforums_list) return null;

      return (
        <div
          key={key}
          {...bemClassesBlockForumList('text', 'col')}
          children={checkTitle(item, bemClassesBlockForumList)}
        />
      );
    });

    if (!!children && !!children.length && children.length > 3) {
      childrenContent = <div {...bemClassesBlockForumList('descriptionContent')} children={childrenContent} />;
    }

    return (
      <Row {...bemClassesBlockForumList('row', { item: true, main: true, developers: isDeveloper })}>
        <Col {...bemClassesBlockForumList('col', 'developer')} allPr={0}>
          <Row {...bemClassesBlockForumList('row', 'developer')}>
            {imgId &&
              <Col {...bemClassesBlockForumList('col', 'img')} allAuto allPr={0}>
                <div {...bemClassesBlockForumList('imgContainer')}>
                  <div {...bemClassesBlockForumList('img')}>
                    {checkTitle(data, bemClassesBlockForumList, 'fill')}
                    <Thumb
                      {...bemClassesBlockForumList('imgPic')}
                      image={imgDeveloper}
                    />
                    <JsonLd
                      item={{
                        '@context': 'https://schema.org',
                        '@type': 'ImageObject',
                        name: get(imgDeveloper, 'alt', null),
                        description: get(imgDeveloper, 'title', null),
                        contentUrl: imgId ? absoluteUrl(`/images/thumb/full/${imgId}`) : null
                      }}
                    />
                  </div>
                </div>
              </Col>
            }
            <Col {...bemClassesBlockForumList('col', 'info')} allPr={0}>
              <div {...bemClassesBlockForumList('topic')} children={checkTitle(data, bemClassesBlockForumList)} />
              {isDeveloper && websiteDeveloper &&
                <div {...bemClassesBlockForumList('site')}>
                  <Link
                    {...bemClassesBlockForumList('link')}
                    href={websiteDeveloper}
                    title={websiteDeveloper}
                    external
                    blank
                  >
                    <span {...bemClassesBlockForumList('linkContent')}>
                      <span
                        {...bemClassesBlockForumList('text')}
                        children={urlShortener(websiteDeveloper)}
                      />
                    </span>
                  </Link>
                </div>
              }
              {data.lc_url &&
                <div {...bemClassesBlockForumList('site')}>
                  <Link
                    {...bemClassesBlockForumList('link')}
                    builder={linksDomik.sectionLanding}
                    builderProps={{
                      title: data.lc_display_name,
                      url: data.lc_url
                    }}
                    noRedirector
                  >
                    <span {...bemClassesBlockForumList('linkContent')}>
                      <span {...bemClassesBlockForumList('text')} children={data.lc_display_name} />
                    </span>
                  </Link>
                </div>
              }
              {!inFavorites && !isDeveloper && data.forum_description && !data.lc_url &&
                <div {...bemClassesBlockForumList('description')}>
                  <div
                    {...bemClassesBlockForumList('text')}
                    dangerouslySetInnerHTML={{ __html: data.forum_description }}
                  />
                </div>
              }
              {!inFavorites && !isDeveloper && !!children && !!children.length &&
                <div
                  {...bemClassesBlockForumList(
                    'description',
                    {
                      block: children.length < 3,
                      table: children.length > 3,
                      list: children.length === 3
                    }
                  )}
                  children={childrenContent}
                />
              }
            </Col>
          </Row>
        </Col>
        <Col {...bemClassesBlockForumList('col', 'theme')} mpFlex allPr={0}>
          <div {...bemClassesBlockForumList('label')} children={i18n('topics')} />
          <div {...bemClassesBlockForumList('text')} children={data.forum_topics ? beautifyNumber(data.forum_topics) : 0} />
        </Col>
        <Col {...bemClassesBlockForumList('col', 'reply')} mpFlex allPr={0}>
          <div {...bemClassesBlockForumList('label')} children={i18n('replies')} />
          <div {...bemClassesBlockForumList('text')} children={data.forum_posts ? beautifyNumber(data.forum_posts) : 0} />
        </Col>
        {inFavorites &&
          <Col {...bemClassesBlockForumList('col', 'favorites')} allPr={0}>
            <FavoritesButton
              {...bemClassesBlockForumList('favorites')}
              project="forum"
              entity="publication"
              entityId={data.id}
              subgroup="forum_forum"
              inFavorites
              hideLabel
            />
          </Col>
        }
      </Row>
    );
  }
}

export function checkTitle(item = {}, classes, mods = null) {
  let linkProps = {
    builder: linksForum.sectionForumTopics,
    builderProps: item
  };

  if (item.forum_type === 'link') {
    linkProps = {
      title: item.forum_title,
      href: item.forum_link,
      external: true,
      blank: true
    };
  }

  return (
    <Link
      {...classes('link', mods)}
      {...linkProps}
    >
      <span {...classes('linkContent')}>
        <span
          {...classes('text')}
          dangerouslySetInnerHTML={{ __html: item.forum_title }}
        />
      </span>
    </Link>
  );
}

export function checkParentTitle(item = {}, classes, mods = null) {
  let linkProps = {
    builder: linksForum.sectionForumTopics,
    builderProps: {
      title: item.parent_forum_title,
      forumUrl: item.parentForumUrl
    }
  };

  if (item.forum_type === 'link') {
    linkProps = {
      title: item.parent_forum_title,
      href: item.parentForumUrl,
      external: true,
      blank: true
    };
  }

  return (
    <Link
      {...classes('link', mods)}
      {...linkProps}
    >
      <span {...classes('linkContent')}>
        <span
          {...classes('text')}
          dangerouslySetInnerHTML={{ __html: item.parent_forum_title }}
        />
      </span>
    </Link>
  );
}

export function checkButton(item = {}, classes, mods = null) {
  return (
    <Button
      {...classes('link', mods)}
      title={item.forum_title}
      customRenderer
    >
      <span {...classes('linkContent')}>
        <span
          {...classes('text')}
          dangerouslySetInnerHTML={{ __html: item.forum_title }}
        />
      </span>
    </Button>
  );
}
