/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import map from 'lodash/map';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import beautifyNumber from 'helpers/beautifyNumber';
import urlShortener from 'helpers/urlShortener';
import DataProvider from 'api/data-provider';
import Link from 'components/link';
import sensor from 'components/sensor';
import Row, { Col } from 'components/row';
import { Thumb } from 'components/image';
import Slick from 'components/slick-slider';

import linksForum from 'src-sites/forum/links';

import translates from '../forum-i18n';

import 'styles/forum/section-forum/_view-tile-developer.scss';

const bemClasses = new Bem('viewTileDeveloper');
const bemClassesContainer = new Bem('viewTilesContainer');

@I18nHoc(translates())
class AdForumsItem extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({})
  };

  render() {
    const { i18n } = this;
    const { className, data = [] } = this.props;

    if (!data.id) return null;

    const forum_link = data.developer_website || data.forum_link;
    const forum_title = data.developer_website || data.forum_title;
    const forum_name = data.developer_name || data.forum_name;
    const forum_img_id = data.developer_avatar || data.forum_logo;
    const imgDeveloper = { id: forum_img_id, alt: forum_name, title: forum_name };

    return (
      <div {...bemClasses({ extra: className })}>
        <Row {...bemClasses('row', 'item main tile')} allColumn>
          <Col {...bemClasses('col', 'img')} allAuto allPr={0}>
            <div {...bemClasses('imgContainer')}>
              <div {...bemClasses('img')}>
                <Thumb
                  {...bemClasses('imgPic')}
                  image={imgDeveloper}
                />
                <JsonLd
                  item={{
                    '@context': 'https://schema.org',
                    '@type': 'ImageObject',
                    name: get(imgDeveloper, 'alt', null),
                    description: get(imgDeveloper, 'title', null),
                    contentUrl: forum_img_id ? absoluteUrl(`/images/thumb/full/${forum_img_id}`) : null
                  }}
                />
              </div>
            </div>
          </Col>
          <Col {...bemClasses('col', 'info')} allPr={0}>
            <Link
              {...bemClasses('link', 'fill')}
              builder={linksForum.sectionForumTopics}
              builderProps={data}
            >
              <span />
            </Link>
            <div {...bemClasses('topic')}>
              <span
                {...bemClasses('text')}
                children={forum_name}
              />
            </div>
            {forum_link &&
              <div {...bemClasses('site')}>
                <Link
                  {...bemClasses('link')}
                  href={forum_link}
                  title={forum_title}
                  external
                  blank
                >
                  <span {...bemClasses('linkContent')}>
                    <span {...bemClasses('text')} children={urlShortener(forum_link)} />
                  </span>
                </Link>
              </div>
            }
          </Col>
          <Col {...bemClasses('col', 'theme')} allPr={0}>
            <span {...bemClasses('label')} children={i18n('topics')} />
            <span {...bemClasses('text')} children={data.forum_topics ? beautifyNumber(data.forum_topics) : 0} />
          </Col>
        </Row>
      </div>
    );
  }
}

@sensor
export default class BannerForumsList extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    forumId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  };

  constructor(...args) {
    super(...args);

    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'desktop',
      clientSide: false
    };
  }

  componentDidMount() {
    this.setState({ clientSide: true });
    this.onHandleResize(this.sensor.getRuntime());
  }

  componentDidUpdate() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;

    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  renderAdForums = ({ data = [] }) => {
    if (!data || !data.length) return null;

    const { className } = this.props;
    const breakpointDesktop = (this.sensor.getVariable('breakpointDesktop') || 1250) - 1;
    const breakpointTablet = (this.sensor.getVariable('breakpointTablet') || 768) - 1;

    return (
      <div {...bemClassesContainer({ extra: className })}>
        <Slick
          slidesToShow={4}
          initialSlide={0}
          responsive={[{
            breakpoint: breakpointDesktop,
            settings: {
              slidesToShow: 3,
              initialSlide: 3
            }
          }, {
            breakpoint: breakpointTablet,
            settings: {
              slidesToShow: 1
            }
          }]}
        >
          {map(data, (it) => {
            return (
              <AdForumsItem
                key={it.id}
                data={it}
              />
            );
          })}
        </Slick>
      </div>
    );
  }

  render() {
    const { forumId } = this.props;

    return (
      <DataProvider
        url="forum/banner-forums"
        query={{
          'forum-id': forumId
        }}
        injectPropName="data"
        renderer={this.renderAdForums}
      />
    );
  }
}
