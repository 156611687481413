import React, { Component } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import PropTypes from 'prop-types';
import { routerShape } from 'react-router';
import get from 'lodash/get';
import each from 'lodash/each';
// import map from 'lodash/map';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import cacheTerms from 'helpers/cacheTerms';
import preload from 'api/data-preload';
import DataProvider from 'api/data-provider';
import seoPreload, { seoDecorator } from 'api/seo';
import {
  authConnect,
  // UserProp
} from 'auth';
// import Perms from 'auth/perms';
// import Block, { BlockHeader, BlockContent } from 'components/block';
// import { SearchInput } from 'components/header';
import contentBlock, { blockPreloader } from 'containers/content-block';
// import Link from 'components/link';
// import Button from 'components/button';
// import Row, { Col } from 'components/row';
// import Accordion from 'components/accordion';
import sensor from 'components/sensor';
import AdSense from 'components/adsense';
import Container from 'components/container';
import Breadcrumbs from 'components/breadcrumbs';
import { OpenGraphImage } from 'components/image';

import linksForum from 'src-sites/forum/links';

import ForumListMain from '../forums/main-forum-list';
// import StatisticsBlock from '../common/statistics';
import blocksConfig from '../blocks/config';
import BlockWrapper from '../blocks/block-wrapper';

import translates from '../forum-i18n';

import 'styles/forum/page-home/_page-home.scss';

const bemClasses = new Bem('pageMain');
// const bemClassesUnreadMessages = new Bem('unreadMessages');

@preload({
  ...seoPreload({ entity: 'mainPage' }, []),
  ...blockPreloader({
    project: 'forum',
    entity: 'mainPage'
  }, blocksConfig, []),
  // Данные рут форумов
  rootForums: [DataProvider.preload, {
    url: 'forum/forum',
    cache: cacheTerms.forumsList,
    query: {
      'root-forums': 1,
      'order-by': 'weight'
    }
  }],
  firstMainForums: [DataProvider.preload, {
    url: 'forum/forum',
    query: {
      'first-main-forums': true,
      'order-by': 'forum_title'
    }
  }],
  secondMainForums: [DataProvider.preload, {
    url: 'forum/forum',
    query: {
      'second-main-forums': true,
      'order-by': 'forum_title'
    }
  }],
})
@authConnect()
@seoDecorator({
  defaultTitle: 'Forum.domik.ua',
  advancedVars: {
    bottomText: 'Текст в самом низу'
  }
})
@I18nHoc(translates())
@sensor
export default class Home extends Component {
  static propTypes = {
    className: PropTypes.string,
    router: routerShape,
    rootForums: PropTypes.shape({}),
    firstMainForums: PropTypes.shape({}),
    secondMainForums: PropTypes.shape({}),
    // auth: UserProp,
    // checkPermission: PropTypes.func
  };

  constructor(...props) {
    super(...props);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;

    this.state = {
      device: 'phone'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    const { device } = this.state;
    if (desktop && device !== 'desktop') return this.setState({ device: 'desktop' });
    if (tablet && device !== 'tablet') return this.setState({ device: 'tablet' });
    if (phone && device !== 'phone') return this.setState({ device: 'phone' });
  }

  /*
  topSubMenu() {
    const { auth = {}, checkPermission } = this.props;

    return (
      <Row>
        {checkPermission(Perms.FORUM_CONTROL_AUTOFILTER) &&
          <Col allFlex allJustifyCFlexEnd allPr={0} {...bemClassesUnreadMessages()}>
            <Link
              {...bemClassesUnreadMessages('link')}
              builder={linksForum.pageFilteredMessages}
            >
              <span {...bemClassesUnreadMessages('linkContent')}>
                <span {...bemClassesUnreadMessages('text')}>Автофильтр</span>
              </span>
            </Link>
          </Col>
        }
        {auth.authenticated &&
          <Col allFlex allJustifyCFlexEnd allPr={0} {...bemClassesUnreadMessages()}>
            <Link
              {...bemClassesUnreadMessages('link')}
              builder={linksForum.pageUnreadMessages}
            >
              <span {...bemClassesUnreadMessages('linkContent')}>
                <span {...bemClassesUnreadMessages('text')}>Непрочитанные сообщения</span>
              </span>
            </Link>
          </Col>
        }
      </Row>
    );
  }

  getMainForums = (val) => {
    const { i18n } = this;
    const { device } = this.state;

    const content = (
      <ul {...bemClasses('ul')}>
        {map(get(this.props, `${val}.data`, []), (it, key) => (
          <li {...bemClasses('li')} key={key}>
            <Link
              {...bemClasses('link')}
              builder={linksForum.sectionForums}
              builderProps={it}
            >
              <span {...bemClasses('linkContent')}>
                <span {...bemClasses('text', 'link')} children={it.forum_title} />
              </span>
            </Link>
          </li>
        ))}
      </ul>
    )

    if (device !== 'phone') return (
      <>
        <div {...bemClasses('ulTopic')}>
          <span {...bemClasses('text')} children={i18n(val)} />
        </div>
        {content}
      </>
    );

    return (
      <Accordion
        {...bemClasses('accordion', 'welcome')}
        panelTopic={i18n(val)}
      >
        <panel {...bemClasses('panel')}>
          <div {...bemClasses('accordionContent')} children={content} />
        </panel>
      </Accordion>
    )
  }
  */

  renderContentBlock = (name) => {
    return contentBlock(blocksConfig, name, this.props, { wrapper: BlockWrapper, withContainer: false, location: get(this, 'props.location') })
  }

  render() {
    const { i18n } = this;
    const { className, rootForums, seoData, router } = this.props;
    // const firstMainForums = this.getMainForums('firstMainForums');
    // const secondMainForums = this.getMainForums('secondMainForums');
    const topic = i18n('h1Main');
    const header = get(seoData, 'header') ? seoData.header : topic;
    const dataBreadcrumbs = [
      {
        name: i18n('breadcrumbsMain'),
        builder: linksForum.sectionForum,
        builderProps: { title: i18n('breadcrumbsMain') }
      }
    ];

    return (
      <div {...bemClasses({ extra: className })}>
        <LazyHydrate ssrOnly>
          <OpenGraphImage />
        </LazyHydrate>
        {/*
        <Container body>
          {this.topSubMenu()}
        </Container>
        */}
        {/* TODO: пострелиз
        <div {...bemClasses('welcomeSearch')}>
          <Container {...bemClasses('content')} body>
            <h1 {...bemClasses('text', 'h1')} children={header} />
            <div {...bemClasses('blockSearch')}>
              <div {...bemClasses('blockSearchContent')}>
                <SearchInput
                  {...bemClasses('search', 'home')}
                  router={router}
                  linkBuilder={linksForum.sectionSearch}
                  queryParamName="search"
                  placeholder={i18n('searchInSection')}
                  otherProps={{
                    btnSearch: {
                      label: i18n('find'),
                      iconType: ''
                    }
                  }}
                />
                <Row {...bemClasses('row', 'afterSearch')} mpColumn>
                  <Col {...bemClasses('col')} allPr={0} children={firstMainForums} />
                  <Col {...bemClasses('col')} allPr={0} children={secondMainForums} />
                  <Col {...bemClasses('col')} allPr={0}>Какой-то Застройщик</Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
        */}
        <Container body>
          <div {...bemClasses('text', 'h1')}>
            <h1 children={header} />
          </div>
          <DataProvider
            {...bemClasses('forumList')}
            preloaded={rootForums}
            url="forum/forum"
            cache={cacheTerms.forumsList}
          query={{
            'root-forums': 1,
            'order-by': 'weight'
          }}
          renderMethod="list"
          >
            <ForumListMain
              entity='page-home'
              renderContentBlock={this.renderContentBlock}
              router={router}
            />
          </DataProvider>
          <AdSense />
          {contentBlock(blocksConfig, 'bottom1', this.props, { wrapper: BlockWrapper, withContainer: false, location: get(this, 'props.location') })}
          {contentBlock(blocksConfig, 'bottom2', this.props, { wrapper: BlockWrapper, withContainer: false, location: get(this, 'props.location') })}
          {contentBlock(blocksConfig, 'bottom3', this.props, { wrapper: BlockWrapper, withContainer: false, location: get(this, 'props.location') })}
          {contentBlock(blocksConfig, 'bottom4', this.props, { wrapper: BlockWrapper, withContainer: false, location: get(this, 'props.location') })}
          {contentBlock(blocksConfig, 'bottom5', this.props, { wrapper: BlockWrapper, withContainer: false, location: get(this, 'props.location') })}
          {contentBlock(blocksConfig, 'bottom6', this.props, { wrapper: BlockWrapper, withContainer: false, location: get(this, 'props.location') })}
          {/*
          <div>
            <StatisticsBlock />
          </div>
          */}
          <Breadcrumbs {...bemClasses('breadcrumbs')} data={dataBreadcrumbs} />
        </Container>
      </div>
    );
  }
}
