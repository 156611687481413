// import formatDateTranslates from 'helpers/format-date-i18n.json';
import formTranslates from 'containers/form/form-i18n';

export default function () {
  return {
    // ...formatDateTranslates,
    ...formTranslates(),

    breadcrumbsMain: {
      ru: 'Главная',
      ua: 'Головна'
    },
    btnClose: {
      ru: 'Закрыть',
      ua: 'Закрити'
    },

    h1Main: {
      ru: 'Форум недвижимости и новостроек',
      ua: 'Форум нерухомості і новобудов'
    },

    h1Search: {
      ru: 'Результаты по запросу{{placeholder}}',
      ua: 'Результати за запитом{{placeholder}}'
    },

    h1Users: {
      ru: 'Сообщения',
      ua: 'Сповіщення'
    },
    allUserPosts: {
      ru: 'Все посты юзера',
      ua: 'Усі cповіщення користувача'
    },
    deleteAllUserPosts: {
      ru: 'Удалить все посты юзера',
      ua: 'Усі cповіщення користувача успішно видалені'
    },
    allUserPostsDeleted: {
      ru: 'Все посты юзера успешно удалены',
      ua: 'Усі cповіщення користувача успішно видалені'
    },

    searchInSection: {
      ru: 'Искать в разделе',
      ua: 'Шукати у розділі'
    },
    searchOnForumDomikUA: {
      ru: 'Поиск на Forum.domik.ua',
      ua: 'Пошук на Forum.domik.ua'
    },
    find: {
      ru: 'Найти',
      ua: 'Знайти'
    },
    searchInTopic: {
      ru: 'Поиск в теме',
      ua: 'Пошук в темі'
    },

    topicNew: {
      ru: 'Новая тема',
      ua: 'Нова тема'
    },

    firstMainForums: {
      ru: 'Новостройки Киева',
      ua: 'Новобудови Києва'
    },

    secondMainForums: {
      ru: 'Новостройки пригорода',
      ua: 'Новобудови передмістя'
    },

    forums: {
      ru: 'Форумы',
      ua: 'Форуми'
    },
    nowDiscussing: {
      ru: 'Сейчас обсуждают',
      ua: 'Зараз обговорюють'
    },

    topics: {
      ru: 'Тем',
      ua: 'Тем'
    },
    replies: {
      ru: 'Ответов',
      ua: 'Відповідей'
    },
    reply: {
      ru: 'Ответить',
      ua: 'Відповісти'
    },
    subscribeForum: {
      ru: 'Подписаться на форум',
      ua: 'Підписатися на форум'
    },
    unsubscribeForum: {
      ru: 'Отписаться от форума',
      ua: 'Відписатись від форуму'
    },
    subscribeTheme: {
      ru: 'Следить за темой',
      ua: 'Слідкувати за темою'
    },
    unsubscribeTheme: {
      ru: 'Отписаться от темы',
      ua: 'Відписатись від теми'
    },

    cannotReplyTheme: {
      ru: 'Вы не можете отвечать в этой теме!',
      ua: 'Ви не можете відповідати в цій темі!'
    },

    lockedTheme: {
      ru: 'Тема закрыта',
      ua: 'Тема закрита'
    },

    investorRegister: {
      ru: 'Реестр инвесторов',
      ua: 'Реєстр інвесторів'
    },
    registerInvestor: {
      ru: 'реестр инвесторов',
      ua: 'реєстр інвесторів'
    },
    ready: {
      ru: 'Сдан',
      ua: 'Зданий'
    },
    addingRegistry: {
      ru: 'Добавление в реестр',
      ua: 'Додавання в реєстр'
    },
    notFindHome: {
      ru: 'Не нашли свой дом?',
      ua: 'Не знайшли свій будинок?'
    },
    dataSend: {
      ru: 'Отправить данные',
      ua: 'Надіслати дані'
    },
    noRegisteredInvestorsYet: {
      ru: 'В этом реестре пока нет зарегистрированных инвесторов',
      ua: 'У цьому реєстрі поки немає зареєстрованих інвесторів'
    },
    registeredInvestorsTableFloor: {
      ru: 'Этаж',
      ua: 'Поверх'
    },
    registeredInvestorsTableRooms: {
      ru: 'Комнат',
      ua: 'Кімнат'
    },
    registeredInvestorsTableFootage: {
      ru: 'Метраж',
      ua: 'Метраж'
    },
    registeredInvestorsTableNickOnForum: {
      ru: 'Ник на форуме',
      ua: 'Нік на форумі'
    },
    registeredInvestorsCanRemoveInformation: {
      ru: 'Вы можете удалить свою информацию из реестра инвесторов. В дальнейшем если вы передумаете, или решите изменить данные, вы сможете снова добавить их в реестр',
      ua: 'Ви можете видалити інформацію з реєстру інвесторів. Надалі, якщо ви передумаєте, або вирішите змінити дані, ви зможете знову додати їх до реєстру'
    },
    administratorHelp: {
      ru: 'Помощь администратора',
      ua: 'Допомога адміністратора'
    },
    administratorNeedHelp: {
      ru: 'Нужна помощь администратора',
      ua: 'Потрібна допомога адміністратора'
    },
    btnDeleteInformation: {
      ru: 'Удалить информацию',
      ua: 'Видалити інформацію'
    },
    btnAddRegistry: {
      ru: 'Добавить в реестр',
      ua: 'Додати до реєстру'
    },
    ifYouInvestor: {
      ru: 'Если вы инвестор данного ЖК, добавьте себя в реестр. Так вы сможете видеть тенденцию проданных квартир и активность инвесторов вашего ЖК.',
      ua: 'Якщо ви інвестор цього ЖК, додайте себе до реєстру. Так ви зможете бачити тенденцію проданих квартир та активність інвесторів вашого ЖК.'
    },
    noDataText: {
      ru: 'нет данных',
      ua: 'немає даних'
    },
    readAllTopics: {
      ru: 'Отметить все темы прочитанными',
      ua: 'Позначити всі теми прочитаними'
    },
    readAllTopicsDone: {
      ru: 'Все темы отмечены как прочитанные',
      ua: 'Усі теми відмічені як прочитані'
    },
    askQuestionNotification: {
      ru: 'Спасибо, ваше сообщение отправлено.',
      ua: 'Дякуємо, ваше повідомлення надіслано.'
    },
    askQuestionModalTopic: {
      ru: 'Задайте вопрос',
      ua: 'Задайте питання'
    },
    canRegisterOnlyOneApartment: {
      ru: 'Вы можете зарегистрировать только 1 квартиру',
      ua: 'Ви можете зареєструвати лише 1 квартиру'
    },
    orderAddInformation: {
      ru: 'Для того чтобы добавить информацию, у Вас должно быть хотя бы одно сообщение в обсуждении этого ЖК. Вы можете, например, написать "Привет" или представиться\u00a0',
      ua: 'Щоб додати інформацію, у Вас має бути хоча б одне повідомлення в обговоренні цього ЖК. Ви можете, наприклад, написати "Привіт" або представитися\u00a0'
    },
    inYourThread: {
      ru: 'в вашей ветке обсуждения',
      ua: 'у вашій гілці обговорення'
    },
    youHaveAlreadyRegistered: {
      ru: 'Вы уже зарегистрировались в реестрах инвесторов 2-х ЖК. Это максимальное количество регистраций',
      ua: 'Ви вже реєструвалися в реєстрах інвесторів 2-х ЖК. Це максимальна кількість реєстрацій'
    },

    menuPost: {
      ru: 'Меню',
      ua: 'Меню'
    },
    linkToPost: {
      ru: 'Ссылка на пост',
      ua: 'Посилання на пост'
    },
    replyDeletePost: {
      ru: 'Удалить ответ',
      ua: 'Видалити відповідь'
    },
    changeTopicPolls: {
      ru: 'Изменить тему / Опросы',
      ua: 'Змінити тему / Опитування'
    },
    edit: {
      ru: 'Изменить',
      ua: 'Змінити'
    },
    answerChangePost: {
      ru: 'Изменить ответ',
      ua: 'Змінити відповідь'
    },
    topicDeletePost: {
      ru: 'Удалить тему',
      ua: 'Видалити тему'
    },
    topicsDelete: {
      ru: 'Удалить темы',
      ua: 'Видалити теми'
    },
    editPost: {
      ru: '* Правка',
      ua: '* Редагування'
    },
    commentRate: {
      ru: 'Оценить комментарий',
      ua: 'Оцінити коментар'
    },
    like: {
      ru: 'Лайк',
      ua: 'Лайк'
    },
    dislike: {
      ru: 'Дизлайк',
      ua: 'Дізлайк'
    },
    likeCannotYourself: {
      ru: 'Вы не можете сами себе ставить лайк',
      ua: 'Ви не можете самі собі ставити лайк'
    },
    likedAlreadyYou: {
      ru: 'Вы уже поставили лайк',
      ua: 'Ви вже поставили лайк'
    },
    dislikeCannotYourself: {
      ru: 'Вы не можете сами себе ставить дизлайк',
      ua: 'Ви не можете самі собі ставити дізлайк'
    },
    dislikeAlreadyYou: {
      ru: 'Вы уже поставили дизлайк',
      ua: 'Ви вже поставили дізлайк'
    },
    permissionYouNotVote: {
      ru: 'У вас нет прав для голосования',
      ua: 'У вас немає прав для голосування'
    },
    answerClubMustMemberClub: {
      ru: 'Для ответа в клубе, необходимо быть членом данного клуба',
      ua: 'Для відповіді в клубі, необхідно бути членом даного клубу'
    },
    approvalPending: {
      ru: 'Ожидает одобрения',
      ua: 'Очікує схвалення'
    },
    linkPostCopiedClipboard: {
      ru: 'Ссылка на пост скопирована в буфер обмена.',
      ua: 'Посилання на пост скопійована в буфер обміну.'
    },
    addTopic: {
      ru: 'Добавить тему / Опросы',
      ua: 'Додати тему / Опитування'
    }
  };
}
