import React, { Component } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import Bem from 'bemHelper';
import formatDate from 'helpers/formatDate';
import beautifyNumber from 'helpers/beautifyNumber';
import apiDecorator from 'api/api-decorator';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';

import linksForum from 'src-sites/forum/links';

import 'styles/forum/section-forum/_item-developer.scss';
import 'styles/forum/section-forum/_aside-block-list.scss';

const classesBemBlockList = new Bem('asideBlockList');

@apiDecorator
export default class AsideBlockList extends Component {
  static propTypes = {
    topicList: PropTypes.arrayOf(PropTypes.shape({})),
    topic: PropTypes.string,
    quantity: PropTypes.number,
    view: PropTypes.string
  };

  render() {
    const { topic, topicList: content, quantity, view = null } = this.props;

    if (!content || content.length === 0) return null;

    const list = content.slice(0, quantity);
    const uList = (
      <ul {...classesBemBlockList('ul')}>
        {map(list, (item, key) => {
          const url = get(item, 'topicUrl');

          if (!url) return null;

          const value = get(item, 'topic_title');
          const lastTime = get(item, 'topicLastPostTime', '').split(' ');

          return (
            <li key={key} {...classesBemBlockList('item')}>
              <Link
                {...classesBemBlockList('link')}
                builder={linksForum.sectionTopic}
                builderProps={{
                  ...item,
                  topicUrl: item.topicLastPageUrl,
                  hash: `#post-${item.topic_last_post_id}`
                }}
                //title={i18n('goToLastPost')}
                customRenderer
              >
                <span {...classesBemBlockList('linkContent')}>
                  <span {...classesBemBlockList('text', 'link')} children={value} />
                  {lastTime &&
                    <>
                      {'\u0020'}
                      <span
                        {...classesBemBlockList('text', 'date')}
                        children={formatDate({ date: get(lastTime, '[0]'), format: 'dd.mm' })}
                      />
                    </>
                  }
                  {!!item.topic_posts &&
                    <span {...classesBemBlockList('iconContainer')}>
                      <Icon {...classesBemBlockList('icon', 'comment')} type={iconTypes.commentRound} />
                      <span {...classesBemBlockList('text', 'comment')} children={beautifyNumber(item.topic_posts)} />
                    </span>
                  }
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    );

    return (
      <div {...classesBemBlockList(null, view)}>
        {topic &&
          <div {...classesBemBlockList('topic')}>
            <span {...classesBemBlockList('text')} children={topic} />
          </div>
        }
        <div {...classesBemBlockList('content')} children={uList} />
      </div>
    );
  }
}
