import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { authConnect } from 'auth'

import config from '../../../config';

const classes = new Bem('adsbygoogle');

const adDataProject = {
  domik: config.adDataDomik,
  bild: config.adDataBild,
  forum: config.adDataForum
};

@authConnect()
export default class AdSense extends Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    slot: PropTypes.string,
    style: PropTypes.shape({})
  };

  componentDidMount() {
    ((d, s, id, async, cb) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;

      js = d.createElement(s);
      js.id = id;
      js.async = async;
      js.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = cb;
    })(document, 'script', 'google-ads-sdk', 'async', () => {
      (adsbygoogle = window.adsbygoogle || []).push({});
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { className, id, style, auth, slot } = this.props;
    const proj = __CLIENT__ ? window.__PROJECT__ : global.__PROJECT__;
    const defaultAdData = adDataProject[proj];

    if (!auth || !defaultAdData) return null;
    if (auth.ip) {
      if (['93.175.234.57', '92.60.190.85', '82.193.123.146'].indexOf(auth.ip) > -1) {
        return null;
      }
    }

    const idIns = id || config.adId;
    const styleIns = { display: 'block', width: '100%', overflow: 'hidden', ...style };
    const dataIns = defaultAdData;
    const count = dataIns.length;
    const randomData = count ? Math.floor(Math.random() * count) : 0;
    const currentIns = get(dataIns, `[${randomData}]`, {});
    const slotIns = slot || currentIns.slot;
    const formatIns = currentIns.format;

    return (
      <ins
        {...classes(null, null, className)}
        style={styleIns}
        data-ad-client={`ca-pub-${idIns}`}
        data-ad-slot={slotIns}
        data-ad-format={formatIns}
        data-full-width-responsive="true"
      />
    );
  }
}
